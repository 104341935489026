<template>
    <v-card tile>
        <v-toolbar dense dark color="primary" height="40" class="mb-4">
            <v-toolbar-title class="subtitle-1">{{title}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn small icon @click.stop="$emit('close')">
                <v-icon small>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                    <v-col cols="12" lg="4">
                        <v-select
                            solo
                            dense
                            label="Type"
                            placeholder="Contact type"
                            required
                            :rules="[v => !!v || 'Contact type is required']"
                            :value="template.type"
                            :items="types"
                            @change="updateTemplate('type',$event)"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <v-select
                            solo
                            dense
                            label="Language"
                            placeholder="Language type"
                            required
                            :rules="[v => !!v || 'Language type is required']"
                            :value="template.language"
                            :items="languages"
                            @change="updateTemplate('language',$event)"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <v-select
                            solo
                            dense
                            label="Communication"
                            placeholder="Communication type"
                            required
                            :rules="[v => !!v || 'Communication type is required']"
                            :value="template.communication_type"
                            :items="communication_types"
                            @change="updateTemplate('communication_type',$event)"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            solo
                            dense
                            clearable
                            label="Name"
                            placeholder="Enter Descriptive Name"
                            required
                            :rules="[v => !!v || 'Descriptive name is required']"
                            :value="template.name"
                            @input="updateTemplate('name',$event)"
                        ></v-text-field>
                   </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-textarea
                            :value="template.body"
                            solo
                            placeholder="Enter template text"
                            rows="8"
                            @input="updateTemplate('body',$event)"
                            hint="Create a template with valid merge tokens: {applicant}, {property}, {order_id}, {reference_url}"
                            persistent-hint
                            required
                            :rules="[v => (v || '' ).length > 10 || 'The text must be at least 10 characters.']"
                        ></v-textarea>
                      </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" :disabled="saveDisabled" @click="saveTemplate">Save</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    name: "template-dialog",
    components: {},
    props: {
        item: {
            required: false,
            validator: () => (v) => typeof v === "object" || v === null,
        },
    },
    data() {
        return {
            valid: true,
            changed: false,
            saveDisabled: true,
            edit: this.item != null,
            template: this.item
                ? { ...this.item }
                : {
                      name: "",
                      type: "",
                      body: "",
                      language: "",
                      communication_type: "",
                      id: 0,
            },
            types: [
                {
                    text: "Applicant",
                    value: "Applicant",
                },
                {
                    text: "Landlord",
                    value: "Landlord",
                },
                {
                    text: "Employer",
                    value: "Employer",
                },
            ],
            communication_types: [
                {
                    text: "Fax",
                    value: "fax",
                },
                {
                    text: "Text",
                    value: "text",
                },
                {
                    text: "Email",
                    value: "email",
                },
            ],
            languages: [
                {
                    text: "English",
                    value: "en",
                },
                {
                    text: "Spanish",
                    value: "es",
                },
            ],
                
        };
    },
    computed: {
        title: {
            immediate: true,
            get() {
                return this.template.id == 0
                    ? "Creating template"
                    : `Template ID ${this.template.id}`;
            },
        },
    },
    created() {
        this.validateSave();
    },
    methods: {
        validateSave() {
            this.saveDisabled =
                this.template.body.length <= 10 ||
                this.template.name.length === 0 ||
                this.template.type.length === 0 ||
                this.template.communication_type.length === 0 ||
                this.template.language.length === 0 ||
                (this.edit && !this.changed);
        },
        updateTemplate(key, value) {
            this.changed = true;
            this.template[key] = value;
            this.validateSave();
        },
        saveTemplate() {
            this.$emit("save", this.template);
            this.$emit("close");
        },
    },
};
</script>

<style scoped>
</style>