var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { tile: "" } },
    [
      _c(
        "v-toolbar",
        {
          staticClass: "mb-4",
          attrs: { dense: "", dark: "", color: "primary", height: "40" }
        },
        [
          _c("v-toolbar-title", { staticClass: "subtitle-1" }, [
            _vm._v(_vm._s(_vm.title))
          ]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { small: "", icon: "" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.$emit("close")
                }
              }
            },
            [_c("v-icon", { attrs: { small: "" } }, [_vm._v("mdi-close")])],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-text",
        [
          _c(
            "v-form",
            {
              ref: "form",
              attrs: { "lazy-validation": "" },
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "4" } },
                    [
                      _c("v-select", {
                        attrs: {
                          solo: "",
                          dense: "",
                          label: "Type",
                          placeholder: "Contact type",
                          required: "",
                          rules: [
                            function(v) {
                              return !!v || "Contact type is required"
                            }
                          ],
                          value: _vm.template.type,
                          items: _vm.types
                        },
                        on: {
                          change: function($event) {
                            return _vm.updateTemplate("type", $event)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "4" } },
                    [
                      _c("v-select", {
                        attrs: {
                          solo: "",
                          dense: "",
                          label: "Language",
                          placeholder: "Language type",
                          required: "",
                          rules: [
                            function(v) {
                              return !!v || "Language type is required"
                            }
                          ],
                          value: _vm.template.language,
                          items: _vm.languages
                        },
                        on: {
                          change: function($event) {
                            return _vm.updateTemplate("language", $event)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "4" } },
                    [
                      _c("v-select", {
                        attrs: {
                          solo: "",
                          dense: "",
                          label: "Communication",
                          placeholder: "Communication type",
                          required: "",
                          rules: [
                            function(v) {
                              return !!v || "Communication type is required"
                            }
                          ],
                          value: _vm.template.communication_type,
                          items: _vm.communication_types
                        },
                        on: {
                          change: function($event) {
                            return _vm.updateTemplate(
                              "communication_type",
                              $event
                            )
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          solo: "",
                          dense: "",
                          clearable: "",
                          label: "Name",
                          placeholder: "Enter Descriptive Name",
                          required: "",
                          rules: [
                            function(v) {
                              return !!v || "Descriptive name is required"
                            }
                          ],
                          value: _vm.template.name
                        },
                        on: {
                          input: function($event) {
                            return _vm.updateTemplate("name", $event)
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-textarea", {
                        attrs: {
                          value: _vm.template.body,
                          solo: "",
                          placeholder: "Enter template text",
                          rows: "8",
                          hint:
                            "Create a template with valid merge tokens: {applicant}, {property}, {order_id}, {reference_url}",
                          "persistent-hint": "",
                          required: "",
                          rules: [
                            function(v) {
                              return (
                                (v || "").length > 10 ||
                                "The text must be at least 10 characters."
                              )
                            }
                          ]
                        },
                        on: {
                          input: function($event) {
                            return _vm.updateTemplate("body", $event)
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { color: "primary", disabled: _vm.saveDisabled },
              on: { click: _vm.saveTemplate }
            },
            [_vm._v("Save")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }